export enum AuthStatus {
  CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED', // The user was created with a temporary password and must set a new one. Complete the process with confirmSignIn.
  CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE = 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE', // The sign-in must be confirmed with a custom challenge response. Complete the process with confirmSignIn.
  CONFIRM_SIGN_IN_WITH_TOTP_CODE = 'CONFIRM_SIGN_IN_WITH_TOTP_CODE', // The sign-in must be confirmed with a TOTP code from the user. Complete the process with confirmSignIn.
  CONTINUE_SIGN_IN_WITH_TOTP_SETUP = 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP', // The TOTP setup process must be continued. Complete the process with confirmSignIn.
  CONFIRM_SIGN_IN_WITH_SMS_CODE = 'CONFIRM_SIGN_IN_WITH_SMS_CODE', //The sign-in must be confirmed with an SMS code from the user. Complete the process with confirmSignIn.
  CONTINUE_SIGN_IN_WITH_MFA_SELECTION = 'CONTINUE_SIGN_IN_WITH_MFA_SELECTION', // The user must select their mode of MFA verification before signing in. Complete the process with confirmSignIn.
  RESET_PASSWORD = 'RESET_PASSWORD', // The user must reset their password via resetPassword.
  CONFIRM_SIGN_UP = 'CONFIRM_SIGN_UP', // The user hasn't completed the sign-up flow fully and must be confirmed via
  DONE = 'DONE', // The signIn process has been completed.
  "CONFIRM_SIGN_IN_WITH_EMAIL_CODE" = 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE'
}
