import {inject, Injectable} from '@angular/core';

import {AuthStatus} from "../../modules/auth/models/AuthStatus";

import {
  signIn,
  confirmSignIn,
  signOut,
  resetPassword,
  confirmResetPassword,
  fetchAuthSession,
  fetchUserAttributes,
  updatePassword
} from "aws-amplify/auth";
import {Router} from "@angular/router";

interface TotpSetupDetails {
  getSetupUri: (issuer: string) => { href: string };
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private router = inject(Router);
  private nextStepEnabled = false;

  getNextStepEnabled(){
    return this.nextStepEnabled;
  }

  async nextStepsController(nextStep: AuthStatus, totpSetupDetails?: TotpSetupDetails){
    console.log("nextStep==>", nextStep)
    this.nextStepEnabled = true;
    const routes: Partial<Record<AuthStatus, string>> = {
      [AuthStatus.CONFIRM_SIGN_IN_WITH_TOTP_CODE]: '/auth/mfa-verification',
      [AuthStatus.CONFIRM_SIGN_IN_WITH_EMAIL_CODE]: '/auth/mfa-verification',
      [AuthStatus.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED]: '/auth/set-password',
      [AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP]: '/auth/mfa-setup',
      [AuthStatus.DONE]: '/dashboard',
    };
    if(AuthStatus.DONE){
      await this.verifyIfRoleExist()
    }

    const route = routes[nextStep];
    if (route) {
      if (nextStep === AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP && !totpSetupDetails) {
        throw new Error("totpSetupDetails is required for TOTP setup");
      }
      let routeState = {};
      if(
        nextStep === AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP
        && totpSetupDetails
        && typeof totpSetupDetails.getSetupUri === 'function'
      ){
        const { href } = totpSetupDetails.getSetupUri("Supra");
        console.log("totpSetupUrl", href)
        routeState = {
          state: {
            totpSetupUri: href,
          },
        }
      }
      await this.router.navigate([route], routeState);
    } else {
      console.error('Unhandled authentication step:', nextStep);
    }
  }

  async signIn(email: string, password: string): Promise<void> {
    const {nextStep} = await signIn({
      username: email,
      password
    });

    if (nextStep.signInStep === AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP && 'totpSetupDetails' in nextStep) {
      console.log(nextStep)
      await this.nextStepsController(nextStep.signInStep as AuthStatus, nextStep.totpSetupDetails as unknown as TotpSetupDetails);
    } else {
      console.log(nextStep)
      await this.nextStepsController(nextStep.signInStep as AuthStatus);
    }
  }

  async completeNewPasswordChallenge(
    newPassword: string
  ): Promise<void> {
    const {nextStep} = await confirmSignIn({
      challengeResponse: newPassword
    })

    if (nextStep.signInStep === AuthStatus.CONTINUE_SIGN_IN_WITH_TOTP_SETUP && 'totpSetupDetails' in nextStep) {
      await this.nextStepsController(nextStep.signInStep as AuthStatus, nextStep.totpSetupDetails as unknown as TotpSetupDetails);
    } else {
      await this.nextStepsController(nextStep.signInStep as AuthStatus);
    }
  }

  async verifyMFA(code: string): Promise<void> {
    const {nextStep} = await confirmSignIn({
      challengeResponse: code
    })
    await this.nextStepsController(nextStep.signInStep as AuthStatus);
  }

  async signOut() {
    await signOut();
    await this.router.navigate(["/auth/login"]);
  }

  resetPassword(email: string) {
    return resetPassword({
      username: email
    })
  }

  confirmResetPassword(newPassword: string, confirmationCode: string, username: string){
    return confirmResetPassword({
      username,
      confirmationCode,
      newPassword
    })
  }

  async getAuthToken(): Promise<string> {
    try {
      const authSession = await fetchAuthSession();
      if (authSession.tokens && authSession.tokens.accessToken) {
        return authSession.tokens.accessToken.toString();
      } else {
        throw new Error("No access token found in session");
      }
    } catch {
      throw new Error("User not authenticated trying to get token");
    }
  }


  async verifyIfRoleExist(){
    const user = await fetchAuthSession();
    console.log("USER", user)
    // TODO: implement role verification here. If the user doesn't have role we should sign out it
  }

  async getUserAttributes() {
    return await fetchUserAttributes();
  }

  async getUserRoles(): Promise<string[]> {
    try {
      const user = await fetchAuthSession();
      const groups = user.tokens?.idToken?.payload['cognito:groups'] as string[];
      return groups || [];
    } catch (error) {
      console.error('Error obteniendo roles del usuario:', error);
      return [];
    }
  }

  async updatePassword(oldPassword: string, newPassword: string){
    return updatePassword({
      newPassword,
      oldPassword
    });
  }

}
